const dialogs = document.querySelectorAll("dialog");
dialogs.forEach((dialog) => {
  const showButton = dialog.nextElementSibling;
  const closeButton = dialog.lastElementChild;

  dialog.addEventListener("click", function (event) {
    var rect = dialog.getBoundingClientRect();
    var isInDialog =
      rect.top <= event.clientY &&
      event.clientY <= rect.top + rect.height &&
      rect.left <= event.clientX &&
      event.clientX <= rect.left + rect.width;
    if (!isInDialog) {
      dialog.close();
    }
  });
  
  showButton.addEventListener("click", () => {
    dialog.showModal();
  });
  closeButton.addEventListener("click", () => {
    dialog.close();
  });
});
